@import "./common.scss";
@import "./ant_components";
@import "colors";
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&family=Inter:wght@300;400;500;600&family=Noto+Sans+Bengali:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif;
}

/* This will work on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: darkgray #dddddd;
}

/* Target's on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #dddddd;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.pos-relative {
  position: relative;
}

/* ============  Start Custom CSS =========== */

/* APP layout */
.app-wrapper {
  display: grid;
  @include fullDisplay;

  .my-sider {
    @media screen and (max-width: 991px) {
      position: fixed;
      z-index: 999;
    }
  }

  .app-page {
    margin: 5px 16px;

    .page-header {
      margin-top: 5px;

      .ant-page-header {
        padding-inline: unset;

        .ant-page-header-heading-left {
          padding: 0 30px;
        }
      }
    }

    .page-content {
      overflow-y: auto;
      height: calc(100vh - 158px);
      //background: #fff;
      margin-bottom: 10px;
      padding: 16px 24px;
    }
  }
}

.page-header-arrow-left {
  margin: 0 10px;
  color: $primary-color;
  cursor: pointer;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td, :where(.css-dev-only-do-not-override-6edu0s).ant-table-wrapper .ant-table-tbody > tr > th.ant-table-cell-row-hover > td.ant-table-cell-row-hover {
  background: initial;
}

.ant-table-small {
  .ant-table-thead {
    tr {
      th {
        padding: 20px 10px !important;
      }
    }
  }
}

.ant-layout {
  background-color: $bgc-color;
}

.pos-order-details-modal-sheet {
  .ant-drawer-wrapper-body {
    .ant-drawer-body {
      padding: 0;
    }

    .ant-drawer-footer {
      .complete-order-pop-confirm {
        width: 100%;
      }
    }
  }
}

.pos-order-payment-details-drop-down {

  width: 480px;

  ul {
    z-index: 999 !important;
    background-color: white;
    //background: $pos-bgc-color2 !important;
    border: 1px solid $pos-bgc-color4;
    border: #3e3942;

    li {
      .payment-details-flex {
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;

        p {
          margin: 0;
        }
      }
    }
  }
}

.pos-order-payment-details {
  position: absolute;
  width: 100%;
  right: 0;
  top: -50px;

  .payment-details-btn {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    background: #8790AB14;
    color: $text-black-color;
    box-shadow: none;

    &:hover {
      background: #8790AB14;
      color: $text-black-color;
    }

    .btn-name {
      margin: 0;
    }
  }

}

.pos-table-order-list {
  .pos-table-order-list-content {
    display: flex;
    justify-content: space-between;
    background: #F7F9FD;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    align-items: center;

    .order-id-txt {
      color: #8790AB;
      font-size: 18px;
      font-weight: 500;
      margin-right: 12px;
    }

    .order-id {
      font-size: 18px;
      font-weight: 500;
    }

    .table-order-details-btn {
      background-color: #8790AB0F;
    }

  }
}

.category-menu-details-tab {
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin-left: 0;
      padding-right: 5px;
      padding-left: 5px;
      background-color: #8790AB19;
    }

    .ant-tabs-tab-active {
      background-color: $primary-color-fade;
    }
  }
}

.pos-cart-mobile-drawer {
  .ant-drawer-content-wrapper {
    width: 500px !important;

    .ant-drawer-body {
      padding: 0;
    }
  }
}

.invoice-no-vat-text {
  font-size: 10px;
}

.avatar-uploader {
  .ant-upload-list-item-container {

    width: 200px !important;
    height: 200px !important;
  }

  .ant-upload-list-item-actions {
  }

  .anticon {
    width: 24px !important;
    height: 24px !important;

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .anticon-eye {
    margin-right: 20px !important;
  }

}

.ant-table-wrapper .ant-table-container {
  padding-left: 12px;
  padding-right: 12px;
}
