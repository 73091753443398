@import "./colors";

//.ant-input-affix-wrapper {
//  height: 36px;
//}

.custom-input {
  height: 36px;
}

.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-icon {
    font-size: 12px;
  }
}

.ant-btn-primary {
  box-shadow: none;
  padding: 2px 32px;
  height: 36px;
}

.select-customer-stock-payment {
  .ant-select-selector {
    align-items: center;
  }
}

.more-action-btn {

  padding: 0;
  width: 36px;
  background-color: #eeeeee;

  &:hover .more-icon {
    color: $brand-bgc-color;
  }

  .more-icon {
    font-size: 18px;
    color: $dark-ash-color;
  }

}

.custom-select {
  .ant-select-selector {
    height: 36px !important;
    display: flex;
    align-items: center;
  }
}

.custom-range-pricker {
  height: 36px;
}

