.ant-notification-notice-message {
  display: none;
}

.ant-notification-notice-message {
  display: none !important;
}

//.ant-notification-notice-content {
//  display: flex;
//}

.ant-notification-notice {
  width: 100%;
}
