@import "./colors";

$white: #ffffff;
$red: #ff0000;
$border_color: #dddddd;

/* Common */
$padding: 16px;

/* Mixin */
@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -ms-transition: $property;
  transition: $property;
}

@mixin flexContentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexContentRightAlign {
  display: flex;
  justify-content: flex-end;
}

@mixin fullDisplay {
  width: 100%;
  min-height: 100vh;
}

@mixin titleBeforeStyle($bulletPointColor) {
  color: $light-ash-color;
  position: relative;
  padding-left: 20px;
  font-size: 15px;
  margin: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 7px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $bulletPointColor;
  }
}

@mixin bulletPointTitle($bulletPointColor) {
  color: $light-ash-color;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 10px;

  span {
    display: block;
    width: 10px;
    height: 10px;
    background: $bulletPointColor;
    border-radius: 50%;
  }
}

@mixin dashboardCardHeaderTitle {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: $light-ash-color;
}

@mixin dashboardPercentValue($color) {
  font-size: 18px;
  font-weight: 700;
  color: $color;
  margin: 0;
}

@mixin dashboardValueStyle($color) {
  color: $color;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}
