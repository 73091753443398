$primary-color: #03B29A;
$pos-primary-color: #007D41;
$pos-primary-color-fade: rgba(34, 191, 165, 0.06);
$primary-color2: #22bfa5;
$primary-color-fade: #eef9f7;
$primary-color-fade-two: #eef9f7;
$light-ash-color: #8790AB;
$dark-ash-color: rgba(51, 51, 51, 0.84);
$dark-ash-color-2: rgba(51, 51, 51, 0.64);
$text-black-color: #3A3A3A;
$text-black-color-2: #161616;
$bgc-color: #F7F9FD;
$green-color: #15CA73;
$blue-color: #4796FF;
$blue-color2: #a8ccfc;
$red-color: #D9333F;
$red-bg-color: rgba(255, 0, 0, 0.06);
$yellow-color: #FFCC3A;
$pos-bgc-color: #ECEFF5;
$pos-bgc-color2: rgba(135, 144, 171, 0.08);
$pos-bgc-color3: rgba(135, 144, 171, 0.12);
$pos-bgc-color4: rgba(135, 144, 171, 0.16);
$dashboard-tooltip-color: #8790AB;
$app-website-gray: #575A5E;
$app-website-blue: #4796FF;
$app-website-light-bg: #00000007;
$header-bgc-color: #FFFFFF;
$brand-bgc-color: #FFFFFF;
$header-txt-color: #2A2A2A;
$menu-selected-bg: #d1faf4;
$menu-selected-bg2: rgba(34, 191, 165, 0.08);
$menu-selected-bg3: rgba(34, 191, 165, 0.06);
$menu-selected-bg4: rgba(34, 191, 165, 0.12);
$radio-selected-color: #03B29A;
$qr-code-header-bgc: #2A2A2A;
$pos-header-bgc: #FFFFFF;
$pos-content-bgc: #FFF9E4;
$pos-content-bgc4: #ffd542;
$pos-bgc-tow: #8790AB14;
$pos-content-bgc-2: #FFABAC;
$pos-content-bgc-3: #98D7B9;
$pos-border: #00000026;
$pos-bgc-color5: #00000008;
$pos-border-2: #8790AB80;
$pos-order-color: rgba(0, 0, 0, 0.16);
$pos-shadow-color: rgba(0, 0, 0, 0.08);
$text-secondary: #525252;
$text-gray-v2: #A7A2A9;
$text-color7: #525252;
$disable-color: #e0e0e0;
$black-color: #000000;
$black-color-3: #252525;
$gray-color-3: #B5B5B5;